@charset "UTF-8";
@font-face {
  font-family: 'noto-sans-thai';
  src: url("../fonts/notosansthai-bold-webfont.woff2") format("woff2"), url("../fonts/notosansthai-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

/* ตัวปกติ */
@font-face {
  font-family: 'noto-sans-thai';
  src: url("../fonts/notosansthai-regular-webfont.woff2") format("woff2"), url("../fonts/notosansthai-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'noto-sans-thai', Roboto, Lato, sans-serif;
  font-size: 100%;
  /*line-height: 1.8;*/
  color: #414042;
}

.headline {
  position: relative;
}

.headline:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 75%;
  height: 6px;
  background-color: #68C2BD;
  transform: translateX(-50%);
}

.darken-overlay h1 {
  font-size: 3.4em;
}

h2 {
  font-size: 4.2em;
  text-transform: uppercase;
  color: #414042;
  font-weight: normal;
  margin-bottom: 30px;
  position: relative;
}

h2.main-topic {
  font-size: 3.2em;
}

h3 {
  font-size: 2.8em;
  line-height: 1.375em;
  color: #1E2D4D;
  font-weight: bold;
}

h4 {
  font-size: 2.3em;
  line-height: 1.5em;
  color: #303030;
  font-weight: bold;
}

h4.thin {
  font-weight: normal;
}

h5 {
  font-size: 1.9em;
  line-height: 1.5em;
  color: #303030;
  font-weight: normal;
}

table.table-regular tr th {
  font-size: 2.2em;
  line-height: 1.5em;
  color: #1E2D4D;
  font-weight: bold;
}

p, table.table-regular tr td, ul.p-list li, ol.p-list li, ul.list-group li.list-group-item, .panel-body ul li, #news_article ul li, #news_article ol li {
  font-size: 1.8em;
}

.panel-body ul.parsley-errors-list li, ul.list-group.errors-list li.list-group-item {
  font-size: 1.4em;
}

p.sub, footer ul li {
  font-size: 1.7em;
  color: #717072;
}

span.help-block {
  font-weight: normal !important;
  font-size: 1.4em;
}

note {
  font-size: 1.4em;
}

.form-group label.control-label {
  font-size: 1.4em;
  color: #606060;
}

.defaultHr {
  border-color: #68C2BD;
  opacity: 0.2;
}

.defaultFont {
  color: #68C2BD;
}

.greenFont {
  color: #5CAAA4;
}

.whiteFont {
  color: white !important;
}

.grayFont {
  color: #414042 !important;
}

.darkBlueFont {
  color: #1E2D4D !important;
}

.redFont {
  color: #EB6F67;
}

.whiteBg {
  background-color: white;
}

.defaultBg {
  background-color: #68C2BD;
}

.redBg {
  background-color: #EB6F67;
}

.greenBg {
  background-color: #68C2BD !important;
}

.darkGreenBg {
  background-color: #2B7E99;
}

.grayBg {
  background-color: #3E464C;
}

.yellowBg {
  background-color: #E9B659;
}

.successBg {
  background-color: #DEEFD8 !important;
}

.dangerBg {
  background-color: #F1DDDD !important;
}

.infoBg {
  background-color: #D8ECF6 !important;
}

.warningBg {
  background-color: #FBF7E3 !important;
}

.successFont {
  color: #3A753E !important;
}

.dangerFont {
  color: #A84341 !important;
}

.infoFont {
  color: #316F8E !important;
}

.warningFont {
  color: #896C3B !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.justify {
  text-align: justify;
}

.vertical-flip {
  transform: scale(1, -1);
}

.horizontal-flip {
  transform: scale(-1, 1);
}

.form-horizontal {
  clear: right;
}

.floatRight {
  float: right;
}

.allRight {
  float: right;
  clear: right;
}

.clearBoth {
  clear: both;
}

#main table td a {
  color: #414042;
}

a:hover, a:focus {
  cursor: pointer;
  text-decoration: none;
}

a.clickable-box {
  width: 100%;
  height: 100%;
  display: block;
}

.clickable {
  cursor: pointer;
}

.container-fluid .pagination > li.active > span {
  background-color: #68C2BD;
  border-color: #68C2BD;
}

.badge {
  vertical-align: baseline;
}

.btn-group > .btn:first-child, .btn-group > .btn:last-child {
  border-radius: 0;
}

.btn.btn-my-btn {
  background-color: #68C2BD;
  color: white;
}

.btn-group > .btn:not(.disabled) {
  background-color: #3E464C;
  border-color: #454F54 !important;
  color: white !important;
}

.btn-group > .btn.disabled {
  color: #3E464C;
  font-size: 1.7em;
}

.btn-group > .btn:not(.disabled):hover {
  background-color: #E9B659 !important;
  border-color: #E9B659 !important;
  color: #3E464C !important;
}

.btn-group > .btn:not(.disabled):focus, .btn-group > .btn:not(.disabled):active {
  background-color: #999;
}

.btn-group > .btn.disabled, .btn-group > .btn.disabled:hover {
  cursor: default !important;
  opacity: 1 !important;
}

.btn-group > .btn {
  padding-left: 0em;
  padding-right: 0em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-group.defaultBorder .list-group-item {
  border-color: rgba(104, 194, 189, 0.2);
  border-left: none;
  border-right: none;
}

/*  .list-group.defaultBorder .list-group-item:first-child {
  border-top:none;
  }

  .list-group.defaultBorder .list-group-item:last-child {
  border-bottom:none;
  }
  */
.tab-content {
  border-left: 1px solid lightgrey;
}

.notification-header {
  margin-top: -220px;
}

.main-notification {
  height: 11em;
  text-align: center;
}

.main-notification-section, .main-notification-1st-section, .main-notification-last-section {
  line-height: 11em;
  height: 11em;
  text-align: center;
  border-right: solid 1px rgba(255, 255, 255, 0.15);
}

.main-notification-last-section {
  border: none;
}

.main-notification-section {
  display: block;
}

a.main-notification-section:hover {
  background-color: rgba(235, 111, 103, 0.95);
}

.main-notification-section h4 {
  color: white;
  font-size: 1.9em;
  font-weight: normal;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.center h4, .center p {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.breakAll {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
}

.noWrap {
  white-space: nowrap;
}

.container-fluid {
  padding: 15px 5% 15px 5%;
}

#notification.container-fluid {
  padding-top: 0;
  padding-bottom: 0;
}

.bg-grey {
  background-color: #f6f6f6;
  background-clip: padding-box;
}

nav .container-fluid {
  margin-top: 0px;
  padding: 0px;
}

#navbarOffset {
  height: 30px;
}

.navbar {
  padding: 0 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  background-color: #68C2BD;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar li a, .navbar {
  color: #FFF !important;
}

ul.navbar-right {
  margin-right: 2px;
}

ul.navbar-right a {
  padding-right: 18px;
  padding-left: 18px;
}

a.navbar-brand {
  position: absolute;
  left: 0em;
  padding-top: 2em;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 20%;
  max-width: 132px;
  max-height: 70px;
  min-width: 90px;
  min-height: 20px;
  z-index: 1;
}

a.navbar-brand img {
  padding: 0em 0.1em 0.1em 0.1em;
}

a.navbar-brand:hover {
  background: transparent !important;
}

.navbar-top-stripe {
  background-color: none;
  opacity: 0.85;
  height: 60px;
  width: 100%;
  position: absolute;
  top: 2em;
  left: 0em;
  z-index: 1;
}

.navbar-fixed-top {
  border: #F7941E;
  margin: 0px;
  min-height: 0px;
}

#topLogo:hover {
  background-color: #1E2D4D;
}

#topLogo img {
  display: inline-block;
  width: 100%;
}

.navbar-nav > li > a {
  padding-top: 0.53em;
  padding-bottom: 0.53em;
}

div.navbar-collapse > ul.navbar-nav > li.dropdown.active.open > a,
div.navbar-collapse > ul.navbar-nav > li.dropdown.open > a {
  background-color: #2B7E99;
}

.navbar .dropdown-menu {
  background-color: #68C2BD;
  border-top: 2px solid #68C2BD;
  font-size: 0.92em;
  margin-top: 0px;
}

.navbar .navbar-header button {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.navbar-header #homeButton {
  position: absolute;
  font-size: 1.4em;
  line-height: 1.4em;
  padding-top: 0.05em;
  margin-top: 0;
  top: 0em !important;
  left: 0.5em;
  display: block;
}

nav.navbar div.navbar-header #homeButton a span {
  color: white;
  text-shadow: 1px 1px 2px #220604;
}

nav.navbar div.navbar-header #homeButton:hover a span {
  background: transparent !important;
  color: white;
}

nav.navbar div.navbar-header #homeButton a:hover, nav.navbar div.navbar-header #homeButton a:focus {
  text-decoration: none !important;
}

.navbar .navbar-header div {
  padding-top: 0.28em;
  padding-bottom: 0.28em;
}

.navbar .navbar-header div span {
  color: #1E2D4D;
}

.collapseNavMenu:hover, .collapseNavMenu:focus {
  background: transparent !important;
}

.navbar button.collapseNavMenu:hover span, #homeButton:hover span, #homeButton:focus span {
  background-color: #1E2D4D;
}

.navbar-nav li.active a {
  color: #FFF !important;
  background-color: #F7941E !important;
}

.navbar li a:hover, .collapseNavButton:hover, .collapseNavButton:hover span {
  background-color: #1E2D4D !important;
  color: white !important;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
  color: #fff !important;
}

#myNavbar {
  padding-top: 0%;
  border: none;
}

#mainImage {
  height: 250px;
}

#darkStripe {
  background-color: #3E464C;
  height: 130px;
}

.darken-overlay > div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  padding-left: 12em;
  padding-top: 0.5em;
}

.darken-overlay h1 {
  margin-top: 0;
  color: white;
  padding-top: 0.5em;
}

.warning-box-shadow {
  box-shadow: 0 4px 8px 0 rgba(137, 108, 59, 0.1), 0 6px 20px 0 rgba(137, 108, 59, 0.09);
}

.thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
  background-color: #ffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}

.thumbnail-wrapper {
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.thumbnail .thumbnail-header, .thumbnail .thumbnail-body {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.thumbnail-default .thumbnail-header, .thumbnail-default .thumbnail-header h3, .thumbnail-default .thumbnail-header h4 {
  background-color: #68C2BD;
  color: white;
}

.thumbnail-danger .thumbnail-header, .thumbnail-danger .thumbnail-header h3, .thumbnail-danger .thumbnail-header h4 {
  background-color: #F1DDDD;
  color: #A84341;
}

.thumbnail-success .thumbnail-header, .thumbnail-success .thumbnail-header h3, .thumbnail-success .thumbnail-header h4 {
  background-color: #DEEFD8;
  color: #3A753E;
}

.thumbnail-warning .thumbnail-header, .thumbnail-warning .thumbnail-header h3, .thumbnail-header h4 {
  background-color: #FBF7E3;
  color: #896C3B;
}

.thumbnail-info .thumbnail-header, .thumbnail-info .thumbnail-header h3, .thumbnail-info .thumbnail-header h4 {
  background-color: #D8ECF6;
  color: #316F8E;
}

.thumbnail-header h3, .thumbnail-header h4 {
  margin-top: 0;
  padding-top: 0.3em;
  padding-bottom: 0.5em;
  font-weight: normal;
}

.panel {
  margin-bottom: 0.5em;
}

.panel .panel-heading {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.panel .panel-heading h4 {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.panel.form-panel > .panel-heading > h4 {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-weight: normal;
  font-size: 1.8em;
}

.panel-my-panel {
  border-color: rgba(104, 194, 189, 0.7);
}

.panel-my-panel > .panel-heading {
  background-color: #68C2BD;
}

.panel-my-panel > .panel-heading > h4 {
  font-weight: normal;
  color: white;
}

.panel-dark-green {
  border-color: rgba(43, 126, 153, 0.7);
}

.panel-dark-green > .panel-heading {
  background-color: #2B7E99;
}

.panel-dark-green > .panel-heading > h4 {
  font-weight: normal;
  color: white;
}

label.radio-inline {
  font-size: 1.5em;
}

.radio-inline + .radio-inline {
  margin-left: 20px;
}

table.onet-summary thead th, table.onet-summary tbody th {
  font-size: calc(1.1em + 0.4vw);
}

table.onet-summary tbody td {
  font-size: calc(1.2em + 0.2vw);
}

.row.onet-name-list > .col-sm-3 {
  padding: 0.2em;
}

.row.onet-name-list .panel-heading h4 {
  font-size: calc(1.7em + 0.45vw);
}

.row.onet-name-list .panel-body {
  font-size: calc(1em + 0.4vw);
  padding-top: 0.1em;
}

.row.onet-name-list .panel-body p {
  font-size: 1.5em;
  color: #1E2D4D;
  margin-bottom: 0em;
}

.row.onet-name-list .panel-body hr {
  margin-top: 0.4em;
  margin-bottom: 0.2em;
  border-color: #68C2BD;
  opacity: 0.2;
}

.row.admission-list > .col-lg-6 {
  padding: 0.2em;
}

.row.admission-list > .col-lg-6 h4 {
  font-size: 1.8em;
  color: white;
  font-weight: normal;
}

.row.admission-list > .col-lg-6 h4 .badge {
  font-size: 1.0em;
  background-color: #FBF7E3;
  color: #896C3B;
}

.row.admission-list .panel .panel-body .badge {
  background-color: #FBF7E3;
  color: #896C3B;
}

.row.admission-list .badge {
  margin: auto;
}

.row.admission-list .panel-body {
  font-size: calc(0.9em + 0.5vw);
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.slideanim {
  visibility: hidden;
}

.slideUp {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb 
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-indicators li {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(247, 148, 30, 0.2);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-left: 4px;
  margin-right: 4px;
}

.carousel-indicators li.active {
  background-color: #F7941E;
  border-color: #F7941E;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin-left: 4px;
  margin-right: 4px;
}

.circle {
  padding-top: 0.4em;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  background: #EB6F67;
}

.circle p {
  color: #fff;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.create-button {
  display: inline-block;
  float: right;
  margin-top: -1.8em;
  margin-bottom: 2.8em;
}

.pagination-wrapper {
  font-size: 1.4em;
  text-align: center;
}

.page-marker {
  float: right;
  margin-top: -3.5em;
  clear: right;
}

.badge-text-up {
  margin-top: -1.0em;
}

.thumbnail-mod-button-wrapper {
  display: block;
  margin-top: -1.5em;
}

.thumbnail-mod-button {
  display: inline-block;
  float: right;
  margin: 0.4em 0.2em 0em 0.2em;
}

mod-button {
  display: inline-block;
  float: right;
  margin: 1px 2px;
}

.topic-link {
  margin-bottom: 0.2em;
}

.media-mod-button {
  clear: none;
  display: inline-block;
  float: right;
  margin: 0.1em 0.2em 0.1em 0.2em;
}

.tab-padding {
  padding: 2em;
}

.popButton {
  display: inline-block;
}

.popButton, .popButton:active, .popButton:focus {
  background-color: rgba(234, 251, 255, 0) !important;
  border: none !important;
  outline: none !important;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.popButton span {
  margin-top: -0.4em;
}

.uploadWrapper label {
  padding-top: 0.4em;
  font-size: 1.4em;
}

.warning .popover-title {
  background-color: #E9B659;
  color: white;
}

footer ul {
  padding-left: 0em;
  list-style: none;
}

footer h3 {
  color: #5C728C;
}

footer ul li, footer ul li a {
  color: #5E4D46;
}

footer ul li a:hover {
  color: #2B4A70;
  text-decoration: none;
}

#staffs .thumbnail {
  box-shadow: none;
}

@media screen and (min-width: 1200px) {
  #teaching-levels .col-lg-3 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .thumbnail-header h3 {
    font-size: 2.4em;
  }
  .largeHide {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  #newsletters .col-md-2 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .largeShow {
    display: none;
  }
}

@media (max-width: 991px) {
  .darken-overlay h1 {
    font-size: 3.0em;
  }
  #newsletters .col-md-2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .smallShow {
    display: none !important;
  }
  .carousel-indicators {
    bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .btn-group > .btn.disabled {
    color: white;
    background-color: #68C2BD;
  }
  .btn-lg {
    width: 100%;
    margin-bottom: 35px;
  }
  .navbar li {
    padding-left: 1.2em;
  }
  ul.navbar-right {
    margin-right: 20px;
  }
  .smallHide {
    display: none;
  }
  #content .panel table {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  #content .panel table td, #content .panel table th {
    padding: 0.25em;
  }
  .main-notification {
    height: auto;
    padding: 2em 5em 4em 5em;
    margin: 0;
  }
  .notification-header {
    margin: 0;
  }
  .main-notification-section {
    border-bottom: solid 1px rgba(255, 255, 255, 0.15);
    text-align: left;
    border-right: none;
    line-height: 5em;
    height: 5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification-header {
    margin-top: 0px;
  }
  #notification.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  #mainImage {
    height: 250px;
  }
  .darken-overlay > div {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
  }
  .darken-overlay h1 {
    font-size: 2.8em;
  }
  #teaching-levels .col-lg-3 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .thumbnail-wrapper {
    padding-left: 0.4em;
    padding-right: 0.4em;
  }
  .thumbnail-default {
    box-shadow: 0 4px 8px 0 rgba(104, 194, 189, 0.1), 0 6px 20px 0 rgba(104, 194, 189, 0.09);
  }
  .thumbnail-danger {
    box-shadow: 0 4px 8px 0 rgba(168, 67, 65, 0.1), 0 6px 20px 0 rgba(168, 67, 65, 0.09);
  }
  .thumbnail-success {
    box-shadow: 0 4px 8px 0 rgba(58, 117, 62, 0.1), 0 6px 20px 0 rgba(58, 117, 62, 0.09);
  }
  .thumbnail-info {
    box-shadow: 0 4px 8px 0 rgba(49, 111, 142, 0.1), 0 6px 20px 0 rgba(49, 111, 142, 0.09);
  }
  .thumbnail-warning {
    box-shadow: 0 4px 8px 0 rgba(137, 108, 59, 0.1), 0 6px 20px 0 rgba(137, 108, 59, 0.09);
  }
  h2.main-topic {
    font-size: 3.2em;
  }
  ul.nav-tabs > li > a {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .btn-group-justified > .btn, .btn-group-justified > .btn-group, .btn-group-justified > .btn-group > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 576px) {
  .xsmallShow {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .xsmallHide {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar-header .collapseNavButton .dropdown-menu {
    margin-top: -0.4em;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > ul > li > a {
    padding-left: 38px !important;
    font-size: 0.94em !important;
  }
  .navbar-nav .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
  .navbar li {
    padding-left: 1.2em;
  }
  ul.navbar-right {
    margin-right: 20px;
  }
  .smallHide {
    display: none;
  }
  .smallShow {
    display: initial !important;
  }
  .notification-header {
    margin: 0;
  }
  .main-notification {
    margin: 0;
  }
  .main-notification-1st-section, .main-notification-last-section {
    display: block;
  }
  #notification.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .darken-overlay > div {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0em;
  }
}

/*# sourceMappingURL=app.css.map */
